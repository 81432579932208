var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "view" }, [
      _c(
        "section",
        { staticClass: "section--fullScreen section--flexCenter" },
        [
          _c("div", { staticClass: "container" }, [
            _c("h1", { staticClass: "h1" }, [_vm._v("Not Found")]),
            _c("p", { staticClass: "p" }, [
              _vm._v("The page you are looking for does not exist"),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }